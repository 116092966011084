import {
  SET_ALL_CATEGORIES,
  SET_ALL_DATA_BOXES,
  SET_ALL_SETTING_OPS,
  SET_ALL_TAG_LEVELS,
  SET_ALL_TEAMS,
  SET_CREATE_DATA_BOX_STATE,
  SET_DATA_BOX_EDIT_MODE,
  SET_IS_DRAWER_OPEN,
  SET_APP_VERSION,
  RESET_DATABOX_DATA
} from '../constants/databoxConstants'

const initialState = {
  dataBoxesState: {
    dataBoxes: [],
    isLoading: false
  },
  databoxSettings: {},
  isDataBoxDrawerOpen: false,
  createDataBoxState: {
    dataBoxDetails: {},
    inProgress: false
  },
  dataBoxEditModeData: {
    dataBoxDetails: {},
    dataBoxEditMode: false, // flag to indicate edit mode, reset it if update successful
    isLoading: false // flag to indicate API call in progress to fech latest data box details
  },
  categories: [],
  tagLevels: [],
  tagNameById: {},
  teams: [],
  appVersion: ''
}

const databoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_DATA_BOXES:
      return {
        ...state,
        dataBoxesState: {
          dataBoxes: action?.data?.dataBoxes,
          isLoading: action?.data?.isLoading
        }
      }
    case SET_ALL_SETTING_OPS:
      return { ...state, databoxSettings: action.data }
    case SET_IS_DRAWER_OPEN:
      return { ...state, isDataBoxDrawerOpen: action.data }
    case SET_CREATE_DATA_BOX_STATE:
      return {
        ...state,
        createDataBoxState: {
          dataBoxDetails: action.data.details,
          inProgress: action.data.inProgress
        }
      }
    case SET_DATA_BOX_EDIT_MODE:
      return {
        ...state,
        dataBoxEditModeData: { ...action.data }
      }
    case SET_ALL_CATEGORIES:
      return { ...state, categories: action.data }
    case SET_ALL_TAG_LEVELS:
      const tagNameById = {}
      action.data.forEach((tagLevel) => {
        tagLevel.tags.forEach((tag) => {
          tagNameById[tag.id] = { ...tag, tagLevelName: tagLevel.name }
        })
      })
      return { ...state, tagLevels: action.data, tagNameById }
    case SET_ALL_TEAMS:
      return { ...state, teams: action.data }

    case SET_APP_VERSION: {
      return { ...state, appVersion: action.data }
    }

    case RESET_DATABOX_DATA: {
      return { ...initialState }
    }

    default:
      return state
  }
}

export default databoxReducer

export const SET_ALL_DATA_BOXES = 'SET_ALL_DATA_BOXES'
export const SET_ALL_SETTING_OPS = 'SET_ALL_SETTING_OPS'
export const SET_IS_DRAWER_OPEN = 'SET_IS_DRAWER_OPEN'
export const SET_CREATE_DATA_BOX_STATE = 'SET_CREATE_DATA_BOX_STATE'
export const SET_DATA_BOX_EDIT_MODE = 'SET_DATA_BOX_EDIT_MODE'
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES'
export const SET_ALL_TAG_LEVELS = 'SET_ALL_TAG_LEVELS'
export const SET_ALL_TEAMS = 'SET_ALL_TEAMS'
export const SET_APP_VERSION = 'SET_APP_VERSION'
export const RESET_DATABOX_DATA = 'RESET_DATABOX_DATA'
